<template>
  <div id="app">
    <EZUIKitJs />
  </div>
</template>

<script>
import EZUIKitJs from './components/EZUIKitJs.vue'

export default {
  name: 'App',
  components: {
    EZUIKitJs
  }
}
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 100%;
  height: 100%;
}
</style>
